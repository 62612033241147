html {
  scroll-behavior: smooth;
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  border: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #000000;
}

.main {
  margin-top: -100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
}

.full-screen {
  margin-top: 0;
}

a{
  text-decoration: none;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0 1rem;
}

#txt {
  font-family: "Gochi Hand", cursive;
  margin: 0;
  color: rgb(153, 153, 153);
  font-size: 1.5rem;
  text-transform: uppercase;
}
#lan {
  margin: 0;
  font-size: 1.4rem;
}

hr {
  width: 10rem;
  animation: fade 3s ease-in;
}

.flex-div {
  height: 65%;
  display: flex;
  justify-content: center;
  align-items: center;
}

@keyframes fade {
  0% {
    width: 0%;
    opacity: 0;
  }
  100% {
    width: 70%;
    opacity: 1;
  }
}

.container-six {
  height: 100vh;
  background-color: rgb(0, 0, 0);
}

.flex-par {
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  text-align: flex-start;
  align-items: center;
  position: absolute;
  z-index: 1;
}

.flex-par > p {
  margin: 0;
}

.flex-mega {
  height: 80vh;
  display: flex;

  justify-content: center;
  align-items: center;
}

.pink {
  color: rgb(255, 151, 167);
}

.flx-row {
  width: 100%;
  height: 80%;
  display: flex;
}

#pink-two {
  font-size: 1.6rem;
}

#top-bold {
  text-transform: uppercase;
  font-family: "Fugaz One", cursive;
  font-size: 2rem;
  margin: 0;
}
#top-bold-two {
  text-transform: uppercase;
  font-family: "Fugaz One", cursive;
  font-size: 2rem;
  margin: 0;
}

#x {
  color: rgb(179, 179, 179);
  font-size: 1.5rem;
  transition: 300ms;
}

#x:hover {
  cursor: pointer;
  transform: scale(1.2);
  color: rgb(230, 230, 230);
}

@media only screen and (max-width: 360px) {
  .arrow {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
  }
  .links {
    display: flex;
    justify-content: space-between;
    margin-top: 0.5rem;
    margin-right: 2rem;
    margin-left: 2rem;
  }
  .col-2-pics {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 70%;
    margin-top: 1rem;
  }
}
