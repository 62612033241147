.container-five {
  min-height: 100vh;
  background-color: black;
  display: flex;
  flex-direction: column;
  z-index: 2;
  opacity: 0;
  transition: all ease 350ms 500ms;
}

.container-five h1 {
  transform: translateY(2rem);
}

#cerf {
  height: 24rem;
  aspect-ratio: 1;
  object-fit: contain;
  border: 1px solid rgba(136, 136, 136, 0);
  padding: 0.5rem;
  border-radius: 25px;
  background-color: rgba(129, 129, 129, 0.137);
  transition: all 300ms;
  user-select: none;
  margin-bottom: 1rem;
}

@media only screen and (max-width: 600px) {
  #cerf {
    height: 18rem;
  }

}

#cerf:hover {
  cursor: pointer;
  background-color: rgba(129, 129, 129, 0.247);
}

.cerf-row {
  margin: 1rem;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  margin-top: 3rem;
}

.expanded {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

#expanded {
  width: 90vw;
  height: auto;
  object-fit: contain;
}

.resume-col {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 3rem 0 1rem 0;
}

#resume {
  font-size: 2rem;
  color: rgb(255, 151, 167);
  transition: 300ms;
}

#resume:hover {
  transform: scale(1.1);
  color: rgb(250, 170, 182);
}

@media (min-width: 1000px) {
  #expanded {
    width: 60vw;
    height: auto;
  }
}


.slide-in-elliptic {
  opacity: 1;
	-webkit-animation: slide-in-elliptic-top-fwd .7s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-in-elliptic-top-fwd .7s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

.res {
  opacity: 0;
  transition: opacity 600ms ease 1s;
}

.title-fade{
  opacity: 1;
}


/* ----------------------------------------------
 * Generated by Animista on 2022-7-1 23:24:27
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-in-elliptic-top-fwd
 * ----------------------------------------
 */
 @-webkit-keyframes slide-in-elliptic-top-fwd {
  0% {
    -webkit-transform: translateY(-600px) rotateX(-30deg) scale(0);
            transform: translateY(-600px) rotateX(-30deg) scale(0);
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0) rotateX(0) scale(1);
            transform: translateY(0) rotateX(0) scale(1);
    -webkit-transform-origin: 50% 1400px;
            transform-origin: 50% 1400px;
    opacity: 1;
  }
}
@keyframes slide-in-elliptic-top-fwd {
  0% {
    -webkit-transform: translateY(-600px) rotateX(-30deg) scale(0);
            transform: translateY(-600px) rotateX(-30deg) scale(0);
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0) rotateX(0) scale(1);
            transform: translateY(0) rotateX(0) scale(1);
    -webkit-transform-origin: 50% 1400px;
            transform-origin: 50% 1400px;
    opacity: 1;
  }
}
