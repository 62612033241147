.App-container {
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.705);
}

.title {
  margin-top: 1rem;
  display: flex;
  justify-content: center;
  font-size: 2.2rem;
  color: rgb(255, 151, 166);
  font-size: 2.2rem;
  font-family: "Fugaz One", cursive;
  z-index: 3;
}

@media only screen and (max-width: 1000px) {
  .title {
    font-size: 3rem;
  }
}

@media only screen and (max-width: 600px) {
  .title {
    font-size: 2.2rem;
  }
}

@media only screen and (min-width: 1000px) {
  .title {
    font-size: 3rem;
  }
}

.body {
  padding: 0 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-top: 0.5rem;
}

#vid {
  height: 100vh;
  width: 100vw;
  object-fit: cover;
  opacity: 1;
  z-index: -1;
  overflow: hidden;
  position: absolute;
}
.nav {
  height: 3rem;
  justify-content: space-evenly;
  display: flex;
  align-items: center;
}

#nav {
  color: #e4e4e4;
  display: inline;
  font-family: "Carter One", cursive;
  text-transform: lowercase;
  font-size: 1.4rem;
  transition: all 0.4s;
  padding: 0 0.5rem;
  margin-top: -4.5rem;
}

.nav > p {
  transition: all 0.4s;
  padding: 0 0.5rem;
  margin-top: -2.5rem;
}
/* COOL HOVER ANIMATION */
#nav:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 3px;
  background-color: rgb(255, 255, 255);
  transform: scaleY(0);
  transition: transform 0.2s, width 0.4s cubic-bezier(1, 0, 0, 1) 0.2s,
    background-color 0.1s;
  z-index: -1;
}

#nav:hover::before {
  transform: scaleY(1);
  width: 100%;
}

#nav:hover {
  cursor: pointer;
  transform: scale(1.05);
  color: rgb(196, 42, 42);
}

p {
  color: #e4e4e4;
  display: inline;
  font-family: "Carter One", cursive;
  text-transform: lowercase;
  font-size: 1.4rem;
}

@media only screen and (max-width: 1000px) {
  p {
    font-size: 1.8rem;
  }
}

@media only screen and (max-width: 620px) {
  p {
    font-size: 1.2rem;
  }
}

@media only screen and (min-width: 1000px) {
  p {
    font-size: 1.8rem;
  }
}

@media only screen and (max-width: 1000px) {
  #nav {
    font-size: 1.8rem;
  }
}

@media only screen and (max-width: 620px) {
  #nav {
    font-size: 1.2rem;
  }
}

@media only screen and (min-width: 1000px) {
  #nav {
    font-size: 1.8rem;
  }
}

.header {
  display: flex;
  justify-content: center;
}

h2 {
  color: rgb(255, 151, 166);
  font-size: 2.5rem;
  font-family: "Fugaz One", cursive;
  margin: 0;
}

@media only screen and (max-width: 1000px) {
  h2 {
    font-size: 3rem;
  }
}

@media only screen and (max-width: 600px) {
  h2 {
    font-size: 2.2rem;
  }
}

@media only screen and (min-width: 1000px) {
  h2 {
    font-size: 3rem;
  }
}

span {
  color: rgb(255, 151, 166);
  font-size: 2rem;
  text-transform: uppercase;
  font-family: "Fugaz One", cursive;
}
#top {
  text-transform: uppercase;
  font-family: "Fugaz One", cursive;
  font-size: 1.5rem;
  margin: 0;
}
