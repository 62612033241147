.container-two {
  position: relative;
  background-color: rgb(0, 0, 0);
  height: 100vh;
  background: linear-gradient(
    135deg,
    #000000 44%,
    rgba(255, 151, 167, 0.822) 44%,
    rgb(255, 151, 166) 50%,
    #000000 40%
  );

  /* Start #5cbcb0 from 0 and end at 5%, Start #fff at 5% and end at 15%, Start #5cbcb0 again at 15% and end at 100% */

  background-repeat: no-repeat; /* To avoid multiple instances */
}

.container-two h1,
.container-three h1 {
  transform: translateY(1.5rem);
}


.container-three {
  position: relative;
  height: 100vh;
  background-color: black;
  background: linear-gradient(
    135deg,
    #272727 44%,
    rgba(255, 151, 167, 0.801) 34%,
    rgb(255, 151, 167) 50%,
    #000000 40%
  );
}

#playdog {
  height: 30rem;
  /* width: auto; */
  user-select: none;
}

.col-2-pics {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* border: 1px solid white; */
  height: 70%;
  margin-top: 0.5rem;
}

.right-arr {
  color: rgba(231, 231, 231, 0.432);
  font-size: 2.4rem;
  margin: 0 1rem;
  transition: all 400ms;
}

.right-arr:hover {
  transform: scale(1.1);
  color: rgb(255, 255, 255);
  cursor: pointer;
}

.arrow {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}

.slide {
  opacity: 0;
  transition-duration: 1s ease;
}

.slide.active {
  opacity: 1;
  transition-duration: 1s;
}

.margin-less {
  margin-top: 0;
}

.repo-badge {
  padding: 1rem;
  position: absolute;
  top: 35%;
  left: 2rem;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  opacity: .6;
  transition: all 350ms;
  animation: pulse 2s ease-out infinite;
}

@media only screen and (max-width: 600px) {
  .repo-badge {
    top: 78%;
    left: 0;
  }

  .repo-badge > svg {
    font-size: 3.6rem !important;
  }
}

.repo-badge > svg {
  color: white;
  font-size: 4.5rem;
  border: 3px solid rgb(32, 32, 32);
  border-radius: 50%;
  background-color: rgb(32, 32, 32);
}

.repo-badge > p {
  font-size: 1rem;
  margin-top: -0.01rem;
  text-transform: capitalize;
  user-select: none;
} 

.repo-badge:hover {
  opacity: 1;
  -webkit-animation-play-state: paused;
  -moz-animation-play-state: paused;
  -o-animation-play-state: paused;
   animation-play-state: paused;
}

@keyframes pulse {
  0%{
    transform: translateY(0) scale(1); 
  
  }
  50%{
    transform: translateY(10px) scale(1.05);
  }
  100%{
    transform: translateY(0) scale(1);
  }
}