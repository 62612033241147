.EmailMeContainer {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}


.emailMe {
  opacity: 0;
}

.emailMe h3 {
  color: rgb(255, 151, 166);
  font-size: 2.8rem;
  font-family: "Fugaz One", cursive;
}

.vis {
  animation: show 1s ease forwards 850ms;
}

@keyframes show {
  from{
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.emailme-close-btn {
  position: absolute;
  top: 20px;
  left: 20px;
  cursor: pointer;

  aspect-ratio: 1;
  height: 3rem;
  border-radius: 50%;

  font-size: 1.5rem;
  font-family: "Fugaz One", cursive;
  background-color: rgba(64, 64, 64, 0.374);
  color: white;
  border: 1px solid rgba(98, 98, 98, 0.178);
  transition: all 350ms;
}

.emailme-close-btn:hover {
  transform: scale(1.06);
}

.emailme-form {
  padding: 1rem;
}

.emailme-form > input,
.emailme-form > textarea
 {
  width: 95%;
  padding: .8rem;
  margin: .5rem;
  border-radius: 5px;
  border: none;
  font-size: 1.1rem;
  font-family:system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  background-color: rgb(0, 0, 0);
  opacity: .7;
  color: white;
  border: 1px solid rgba(239, 238, 238, 0.46);
  outline: none;
  -webkit-transition: all .2s ease-out;
  -moz-transition: all .2s ease-out;
  -ms-transition: all .2s ease-out;
  -o-transition: all .2s ease-out;
  transition: all .2s ease-out
}

.emailme-form > input:focus,
.emailme-form > textarea:focus {
  opacity: 1;
  /* background-color: rgb(186, 186, 186); */
  color: rgb(239, 238, 238);
  border: 1px solid rgba(239, 238, 238, 0.719);
}

.emailme-form > input:focus::placeholder,
.emailme-form > textarea:focus::placeholder {
  color: rgb(239, 238, 238);
}

.emailme-form > textarea {
  resize: none;
  height: 10rem;
}

.emailme-form > input::placeholder,
.emailme-form > textarea::placeholder {
  font-family: "Fugaz One", cursive;
  color: rgba(245, 245, 245, 0.659);
}

.input-err {
  animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
}

@keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }
  
  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
}

.emailme-form > button {
  padding: .5rem 2rem;
  margin-top: 2rem;
  background-color: transparent;
  font-family: "Carter One", cursive;
  text-transform: lowercase;
  font-size: 1.4rem;
  color: white;
  border: 1px solid white;
  cursor: pointer;
  position: relative;
  transition: all ease 350ms;
  text-transform: capitalize;
}

.emailme-form > button::before {
  content:"";
  position: absolute;
  left: 0;
  top: 0;
  width: 0;
  height: 100%;
  background-color: rgb(222, 4, 4);
  z-index: -1;
  transition: all ease 350ms 350ms;
}

.emailme-form > button:hover:enabled::before {
  width: 100%;
}


.emailme-form > button:hover:enabled {
  transform: scale(1.04);
}

.emailme-form > button:disabled {
  opacity: .2;
  cursor: default;
  background-color: rgb(82, 82, 82);
}